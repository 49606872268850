import type Tag from '~/entities/tag'
import type TagParent from '~/entities/tagParent'
import type TagTypeKey from '~/types/tagTypeKey'

export const availableAllowedSeoTags = [
  'subgenre',
  'country',
  'influencer_kind',
] as TagTypeKey[]

export type AllowedSeoTags = (typeof availableAllowedSeoTags)[number]

export const availableAdditionalTypeKeys = [
  'genre',
  'subgenre_of_genre',
  'subgenre_from_subgenre',
] as const
export type AdditionalTagTypeKeys = (typeof availableAdditionalTypeKeys)[number]

export type ExtendedTagTypeKeys = AllowedSeoTags | AdditionalTagTypeKeys

export interface SeoListing {
  variable_type: ExtendedTagTypeKeys
  fixed_type: ExtendedTagTypeKeys | null
  parent_ids: TagParent['id'][]
  tag_ids: Tag['id'][]
}

// URL priority as expressed from 0 to n, n being the least priority
export const tagPriorities = [
  'subgenre',
  'influencer_kind',
  'country',
] as AllowedSeoTags[]

export type I18nTextKey =
  | `subgenre_influencer_kind`
  | `subgenre_country`
  | `influencer_kind_country`
