import { capitalize } from '~/mixins/global'

import { useGetTranslatedTagEntityName } from '~/composables/useTagHelpers'

import { useTagStore } from '~/stores/tag'

import { availableAdditionalTypeKeys, tagPriorities } from '~/types/seoList'

import type Tag from '~/entities/tag'
import type TagParent from '~/entities/tagParent'
import type { SeoListsAsyncData } from '~/types/influencerListTagSeo'
import type {
  AdditionalTagTypeKeys,
  ExtendedTagTypeKeys,
  I18nTextKey,
} from '~/types/seoList'
import type TagTypeKey from '~/types/tagTypeKey'

type EntityDescriptor = {
  entity: TagParent | Tag
  type: ExtendedTagTypeKeys
}

export function useSeoListGeneration() {
  const { t } = useI18n()
  const getTranslatedTagEntityName = useGetTranslatedTagEntityName()

  const { GET_TAG_FROM_ID, GET_PARENT_FROM_ID } = useTagStore()

  function sortSeoListByType(typeA: TagTypeKey, typeB: TagTypeKey): number {
    return tagPriorities.indexOf(typeA) - tagPriorities.indexOf(typeB)
  }

  function getI18nKeyFromTypes(types: ExtendedTagTypeKeys[]): I18nTextKey {
    return types
      .map((e) => getTagTypeFromExtendedTypes(e))
      .sort(sortSeoListByType)
      .join('_') as I18nTextKey
  }

  /**
   * This fn will take in a extended type and return it's corresponding TagTypeName.
   *
   * @param  extendedType - An extention of the current standart TagTypeKey that includes "genre_from_subgenre" and other seo related type keys.
   * @returns  A string that represents a valid TagTypeKey.
   * @example
   * getTagTypeFromExtendedTypes("subgenre") // returns "subgenre"
   * getTagTypeFromExtendedTypes("genre") // returns "subgenre"
   * getTagTypeFromExtendedTypes("genre_from_subgenre") // returns "subgenre"
   * getTagTypeFromExtendedTypes("country") // returns "country"
   */
  function getTagTypeFromExtendedTypes(
    extendedType: ExtendedTagTypeKeys,
  ): TagTypeKey {
    return availableAdditionalTypeKeys.includes(
      extendedType as AdditionalTagTypeKeys,
    )
      ? 'subgenre'
      : (extendedType as TagTypeKey)
  }

  function generateTitleFromEntityDescriptors(
    variableType: ExtendedTagTypeKeys,
    fixedEntityDescriptor?: EntityDescriptor,
  ): string {
    // Get's the current fixed Entity if fixed type is defined
    // For example if fixed_type is "pop" we're going to get the pop Tag entity
    const currentFixedTag = fixedEntityDescriptor?.entity
    const variableTitle = t(`components.seo.footer.title.${variableType}`, {
      // This handles the "genre" edge case where we need to insert the translated genre name in the sentence
      genre:
        fixedEntityDescriptor?.type === 'genre' && currentFixedTag
          ? getTranslatedTagEntityName(
              currentFixedTag,
              getTagTypeFromExtendedTypes(fixedEntityDescriptor.type),
              2,
            )
          : undefined,
    })

    // Simple one type return
    if (!fixedEntityDescriptor) {
      return capitalize(variableTitle.toString())
    }
    // When faced with multi typing the titles just need an additional wording to them
    else if (currentFixedTag) {
      const fixedTagType = getTagTypeFromExtendedTypes(
        fixedEntityDescriptor.type,
      )
      let fixedType = getTranslatedTagEntityName(
        currentFixedTag,
        fixedTagType,
        2,
      )

      if (fixedTagType === 'country') fixedType = capitalize(fixedType)

      return capitalize(
        t(`components.seo.footer.titleWithFixedValue`, {
          formattedVariableType: variableTitle,
          fixedType,
        }).toString(),
      )
    }
    // Fallback :shrug: better than displaying nothing
    else {
      return variableTitle
    }
  }

  /**
   * @param urlData
   * @param entities
   */
  function generateTopTitleFromUrlData(
    urlData: SeoListsAsyncData['urlData'],
    entities?: (Tag | TagParent)[],
  ): string {
    if (!entities) entities = genEntitiesFromUrlData(urlData)

    if (urlData.length > 1) {
      // We don't need to check priority here since we read from the url directly!
      const i18nKey = urlData.map((data) => data.tagTypeName).join('_')

      return t(`components.seo.title.dualComposition.${i18nKey}`, {
        ...entities.reduce(
          (accumulator, data, index) => {
            const type = urlData[index].tagTypeName
            accumulator[type] = getTranslatedTagEntityName(
              data,
              type,
              2,
            ).toLowerCase()

            if (type === 'country')
              accumulator[type] = capitalize(accumulator[type])

            return accumulator
          },
          {} as Record<TagTypeKey, string>,
        ),
      })
    } else if (urlData[0].tagTypeName !== 'influencer_kind') {
      let tagName = getTranslatedTagEntityName(
        entities[0],
        urlData[0].tagTypeName,
        2,
      ).toLowerCase()

      if (urlData[0].tagTypeName === 'country') tagName = capitalize(tagName)

      return t(`components.seo.title.simple`, {
        tagName,
      })
    } else {
      return t(`components.seo.title.simple_influencer_kind_edge_case`, {
        influencer_kind: getTranslatedTagEntityName(
          entities[0],
          urlData[0].tagTypeName,
          2,
        ).toLowerCase(),
      })
    }
  }

  function genEntitiesFromUrlData(
    urlData: SeoListsAsyncData['urlData'],
  ): (Tag | TagParent)[] {
    return urlData.map((data) => {
      const fetchFn = data.isParent ? GET_PARENT_FROM_ID : GET_TAG_FROM_ID

      return fetchFn(data.tagId)
    }) as (Tag | TagParent)[]
  }

  function generateLink(tagNames: (Tag['name'] | TagParent['name'])[]): string {
    return `/influencer/list/tags/${tagNames.join('/')}/`
  }

  return {
    sortSeoListByType,
    getI18nKeyFromTypes,
    generateTopTitleFromUrlData,
    genEntitiesFromUrlData,
    getTagTypeFromExtendedTypes,
    generateLink,
    generateTitleFromEntityDescriptors,
  } as const
}
